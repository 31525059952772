
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"
import CascadeSelector from '@/views/Planning/components/CasvadeSelector.vue'
import SpreadJsDialog from '@/views/Planning/components/SpreadJsDialog.vue'
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed, watch } from 'vue'
import { getGuidelineYear, setBottomUpYear, getGuidelineList, saveGuideline, refreshKufri, refreshBudget } from '@/API/planning'
import { formatDataForTable } from './components/spreadUtils'
import moment from 'moment'
import { message ,Modal } from "ant-design-vue";
// import { data } from './mock'

function generateKufirVersions(begin: string, end: string) {
  const versions = []
  const month = moment(begin.replace('K', ''))
  const endMonth = moment(end.replace('K', ''))
  while (!month.isAfter(endMonth)) {
    versions.push(month.format('YYYYKMM'))
    month.add(1, 'M')
  }

  return versions
}


const seasonPoint2Fixed = (record: any) => {
  if (record.text || record.text === 0) {
    return record.text.toFixed(2) + '%'
  }
  return ''
}
function setId(data: any [], childrenKey = 'children', key = 'key') {
  let id = 1
  const setId = (data: any []) => {
    data.forEach((item: any) => {
      (item[key] as number) = id
      id++
      if (item[childrenKey] && (item[childrenKey]).length) {
        setId(item[childrenKey])
      }
    })
  }
  setId(data)
  return data
}

export default defineComponent({
  components: {
    CascadeSelector,
    SpreadJsDialog
  },
  setup() {
    const route: any = useRoute()
    const store = useStore()
    const scenarioId = route.query.scenarioId
    const planningId = route.query.planningId
    const scenarioName = route.query.scenarioName
    const lockStatus = route.query.lockStatus === 'true'
    const kufriMonth = route.query.kufriMonth

    const topData = ref<any>({})
    const setBuYear = ref<number[]>([])
    const setBottomUpYearShow = ref<boolean>(false)
    const currentYear = ref<number>(route.query.year)
    const years = ref<any[]>([])
    const dialogYears = ref<any[]>([])
    const expandedRowKeysList = ref<any[]>([]) 
    const expandAllStatus = ref<boolean>(false)
    const resMakeList = ref<string []>([])
    const seasonTotal = [
      {
        title: 'annual'
      },
      {
        title: 'seasonOne'
      },
      {
        title: 'seasonTwo'
      },
      {
        title: 'seasonThree'
      },
      {
        title: 'seasonFour'
      }
    ]

    const seasons = [
      {
        title: 'Annual',
        slot: 'Annual',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'annual.controllingPoint' ,
            key: 'annual.controllingPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'annual.submitPoint',
            key: 'annual.submitPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important',
                  fontWeight: 'bold !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q1',
        slot: 'Q1',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonOne.controllingPoint',
            key: 'seasonOne.controllingPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonOne.submitPoint',
            key: 'seasonOne.submitPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q2',
        slot: 'Q2',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonTwo.controllingPoint',
            key: 'seasonTwo.controllingPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonTwo.submitPoint',
            key: 'seasonTwo.submitPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q3',
        slot: 'Q3',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonThree.controllingPoint',
            key: 'seasonThree.controllingPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonThree.submitPoint',
            key: 'seasonThree.submitPoint',
            width: 100,
            align: 'right',
            customRender: seasonPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q4',
        slot: 'Q4',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonFour.controllingPoint',
            key: 'seasonFour.controllingPoint',
            align: 'right',
            width: 100,
            customRender: seasonPoint2Fixed,
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonFour.submitPoint',
            key: 'seasonFour.submitPoint',
            align: 'right',
            width: 100,
            customRender: seasonPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      }
    ]

    const months = ref<any []>(
      [
        {
          text: '01',
          number: '1',
          title: 'january',
          actualStatus: [],
          width: 100
        },
        {
          text: '02',
          number: '2',
          title: 'february',
          actualStatus: [],
          width: 100
        },
        {
          text: '03',
          number: '3',
          title: 'march',
          actualStatus: [],
          width: 100
        },
        {
          text: '04',
          number: '4',
          title: 'april',
          actualStatus: [],
          width: 100
        },
        {
          text: '05',
          number: '5',
          title: 'may',
          actualStatus: [],
          width: 100
        },
        {
          text: '06',
          number: '6',
          title: 'june',
          actualStatus: [],
          width: 100
        },
        {
          text: '07',
          number: '7',
          title: 'july',
          actualStatus: [],
          width: 100
        },
        {
          text: '08',
          number: '8',
          title: 'august',
          actualStatus: [],
          width: 100
        },
        {
          text: '09',
          number: '9',
          title: 'september',
          actualStatus: [],
          width: 100
        },
        {
          text: '10',
          number: '10',
          title: 'october',
          actualStatus: [],
          width: 100
        },
        {
          text: '11',
          number: '11',
          title: 'november',
          actualStatus: [],
          width: 100
        },
        {
          text: '12',
          number: '12',
          title: 'december',
          actualStatus: [],
          width: 112,
          customHeaderCell: () => {
            return {
              style: {
                'padding-right': '12px !important'
              }
            }
          },
          customCell: () => {
            return {
              style: {
                'padding-right': '12px !important'
              }
            }
          }
        },
      ]
    ) 

    const tableColumns = ref<any []>(
      [
        {
          slots: { title: 'brand' },
          fixed: 'left',
          children: [
            {
              title: 'Brand',
              dataIndex: 'brand',
              width: 130,
              fixed: 'left'
            }
          ]
        },
        {
          slots: { title: 'typeClass' },
          fixed: 'left',
          children: [
            {
              title: 'Type Class',
              dataIndex: 'typeClass',
              width: 100,
            }
          ]
        },
        {
          slots: { title: 'nstGroupName' },
          fixed: 'left',
          children: [
            {
              title: 'Model',
              dataIndex: 'nstGroupName',
              width: 280,
            }
          ]
        },
        ...seasons.map(season => {
          return {
            slots: { title: season.title },
            width: 200,
            align: 'center',
            children: season.children
          }
        }),
        ...months.value.map(month => {
          return {
            slots: {
              title: month.title,
            },
            align: 'right',
            customHeaderCell: month.customHeaderCell,
            children: [
              {
                // title: `${currentYear.value}-${month.text}`,
                align: 'right',
                slots: {
                  customRender: month.text,
                },
                width: month.width,
                customHeaderCell: month.customHeaderCell,
                customCell: month.customCell
              }
            ],
          }
        })
      ]
    )

    const dataSource = ref<string>('guideline')

    const tableData = ref<any[]>([])

    const prePoint = ref<number>(0)

    const number2Fixed = computed(() => {
      return (val: any) => {
        if (val || val === 0) {
          return val.toFixed(2) + '%'
        }
        return ''
      }
    })

    const initMonthData = (obj: any, monthEn: string) => {
      if (!obj) {
        return {
          actualStatus: null,
          buPoint: null,
          budget: null,
          controllingPoint: null,
          dataInfoId: null,
          finalPoint: null,
          kufriVersion: null,
          month: months.value.filter(item => item.title === monthEn)[0].number,
          planningId: null,
          planningTypeId: null,
          releasePoint: null,
          rtLlp: null,
          rtVolume: null,
          submitPoint: null,
          wsLlp: null,
          wsVolume: null,
          year: currentYear.value
        }
      }
    }

    const methodFormatList = (list: any[]) => {
      const monthEns = months.value.map((month: any) => {
        return month.title
      })
      list.forEach((item: any, index: number) => {
        for (const key in item) {
          if (monthEns.includes(key)) {
            if (!item[key]) {
              item[key] = initMonthData(item[key], key)
            }
          }
          if (monthEns.includes(key)) {
            item[key].editing = false
          }
          if (key === 'children' && item[key]) {
            item[key].forEach((child: any) => {
              child.formatBrand = item.brand || item.mappingName?.split('-')[0]
              child.formatBu = item.bu
              child.formatTypeClass = item.typeClass
            })
            methodFormatList(item[key])
          }
        }
        // item.id = index + Math.random() * Math.random() + Math.random()
      })
    }

    const methodFormatSaveData = (make: any, type: string) => {
      const result: any[] = []
      const monthNames = months.value.map((month: any) => month.title)
      if (!make.detailVOList) {
        make.detailVOList = []
      }
      make.detailVOList.forEach((detail: any)=> {
        if (detail.children && detail.children.length > 0) {
          detail.children.forEach((child: any) => {
            for (const key in child) {
              let condition
              if (type === 'save') {
                if (make.controllingSaveStatus) {
                  condition = monthNames.includes(key) && !child[key].actualStatus && child[key].edited
                } else {
                  condition = monthNames.includes(key)
                }                
              } else if (type === 'release') {
                condition = monthNames.includes(key)
              }
              if (condition) {
                const object: any = {}
                object.controllingSavedPoint = child[key].controllingPoint
                object.controllingReleasedPoint = child[key].releasePoint
                object.id = child[key].dataInfoId
                object.buSavedPoint = child[key].buPoint
                object.buSubmitPoint = child[key].submitPoint
                if (!object.id) {
                  object.year = child[key].year
                  object.month = child[key].month
                  object.brand = child.formatBrand
                  object.typeClass = child.formatTypeClass
                  
                  object.nstGroup = child.nstGroup
                  object.nstGroupName = child.nstGroupName
                  object.bu = child.formatBu
                  object.actualStatus = false
                }
                if (type === 'save') {
                  result.push(object)
                } else {
                  if (object.id) {
                    result.push(object)
                  } else {
                    if (child[key].edited) {
                      result.push(object)
                    }
                  }
                }
              }
            }
          })
        }
      })
      return result
    }

    const methodChangeActual = () => {
      months.value.forEach(month => {
        month.actualStatus = tableData.value.map((make: any) => {
          if (currentYear.value > parseInt(make.viewKufriVersion.substring(0, 4))) {
            return false
          }
          else {
            const kufriMonth = make.viewKufriVersion.substring(make.viewKufriVersion.length - 2)
            return month.text < kufriMonth
          }
        })
      })
    }

    const methodGetList = async () => {
      const postData = {
        ...topData.value,
        scenrioId: scenarioId,
        year: currentYear.value,
        kufriMonth: kufriMonth
      }
      const responseList = await getGuidelineList(postData)
      const list = responseList.data || []
      resMakeList.value = list.map((item: any) => {
        return item.make
      })
      // list = data
      tableData.value = list
      tableData.value.forEach(make => {
        make.kufirVersions = generateKufirVersions(make.startKufriVersion, make.currentKufriVersion)
        make.totalExpand = true
        make.viewKufriVersion = make.currentKufriVersion
        // expandedRowKeysList.push([])
        if (!make.detailVOList) {
          make.detailVOList = []
        }
        methodFormatList(make.detailVOList)
        setId(make.detailVOList, 'children', 'id')
        // total的左右滑动与table联动
        nextTick(() => {
          resMakeList.value.forEach((make: string, index: number) => {
            const ele: any | null = document.querySelector(`.${make}${index} .ant-table-body`)
            const title: any | null = document.querySelectorAll(`.${make}${index} .scroll-content`)
            ele.addEventListener('scroll', function(e: any) {
              title.forEach((item: any) => {
                item.style.left = `-${e.target.scrollLeft}px`
              })
            })
          })
        })
      })
      methodChangeActual()
    }

    const initYears = async () => {
      years.value = []
      const params = {
        bu: topData.value.bu,
        buId: topData.value.buId,
        scenrioId: scenarioId,
        kufriMonth: kufriMonth
      }
      const responseYears = await getGuidelineYear(params)
      dialogYears.value = responseYears.data
      const yearNames: any[] = []
      responseYears.data.forEach((item: any) => {
        if (!yearNames.includes(item.year)) {
          yearNames.push(item.year)
          years.value.push(item)
        }
      })
      setBuYear.value = years.value.map((year: any)=> {
        if (year.bottomActive) {
          return year.year
        }
      })
    }

    const handleUpdate = (condition: any) => {
      topData.value = condition
      methodGetList()
      initYears()
    }

    const handleKufriChange = async (kufri: string, makeIndex: number, make: any) => {
      const postData = {
        ...topData.value,
        scenarioId: scenarioId,
        planningId: make.planningId,
        year: currentYear.value,
        kufriVersion: kufri
      }
      const response = await refreshKufri(postData)
      tableData.value[makeIndex].detailVOList = response.data[0].detailVOList || []
      methodFormatList(tableData.value[makeIndex].detailVOList)
      setId(tableData.value[makeIndex].detailVOList, 'children', 'id')
      // 刷新对应kufri月份的acture状态
      methodChangeActual()
    }

    const handleSetBuYearChange = (value: any[]) => {
      if (lockStatus) {
        return message.error('Data locked.')
      }
      setBuYear.value = value
    }

    const handleModalOk = () => {
      const postData = {
        scenarioId: scenarioId,
        yearList: setBuYear.value,
        kufriMonth: kufriMonth
      }
      setBottomUpYear(postData).then(() => {
        setBottomUpYearShow.value = false
        message.success('Set Bottom Up Year Success')
      })
    }

    const handleChooseYear = (year: number) => {
      currentYear.value = year
      expandAllStatus.value = false
      methodGetList()
    }

    // 点击单元格可编辑 item：单元格数据 record：整行数据
    const handleClickCell = (e: any, item: any, record: any, actualStatus: boolean) => {
      if (lockStatus) {
        return message.error('Data locked.')
      }
      if (actualStatus || item.editDisabled) {
        return
      }
      item.editing = true
      prePoint.value = item.controllingPoint
      nextTick(()=>{
        const ele: any | null = document.querySelector('.data-table input')
        if (ele) {
          ele.focus()
        }
      })
    }

    // 获取焦点
    const handleFocus = (record: any, month: string) => {
      // 转化成小数
      // if (record[month].controllingPoint) {
      //   record[month].controllingPoint = record[month].controllingPoint / 100
      // }
    }

    const handleEnter = (e: any) => {
      e.target.blur()
    }

    // 离焦事件 item: 单元格数据 record: 行数据 tableData:表格数据
    const handleBlur = (record: any, month: string, tableData: any) => {
      if (record[month].controllingPoint === 0 || record[month].controllingPoint === '0') {
        record[month].controllingPoint = 0
      } else {
        record[month].controllingPoint = Number(record[month].controllingPoint) || null
      }
      // 转化成百分数
      // if (record[month].controllingPoint) {
      //   record[month].controllingPoint = record[month].controllingPoint * 100
      // }
      if (record[month].controllingPoint !== prePoint.value) {
        record[month].edited = true
        // 是父节点 判断是否有子节点 如果有子节点 父节点编辑 子节点自动变成相同数值且不可编辑
        if (record.brand || record.typeClass) {
          if (record.children) {
            record.children.forEach((child: any) => {
              child[month].controllingPoint = record[month].controllingPoint
              child[month].edited = true
              child[month].editDisabled = !!child[month].controllingPoint
              child[month].empty = !child[month].controllingPoint
            })
          }
        }
        // 是子节点 找到父节点 父节点不可编辑
        if (!record.brand && !record.typeClass && record.nstGroup) {
          tableData.forEach((row: any) => {
            if (row.children) {
              const childControllingPointList: any[] = []
              row.children.forEach((child: any) => {
                childControllingPointList.push(child[month].controllingPoint)
                if (child[month].dataInfoId === record[month].dataInfoId) {
                  row[month].controllingPoint = null
                  row[month].editDisabled = true
                  row[month].empty = false
                }
              })
              // 所有child的controllingPoint都为null时 父节点可编辑
              const bool = childControllingPointList.every((item: any) => {
                return !item
              })
              if (bool) {
                row[month].editDisabled = false
              }
            }
          })
        }
      }
      record[month].editing = false
      if (!record[month].controllingPoint) {
        record[month].empty = true
      }
    }

    const handleForecast = (month: string, monthEn: string, index: number, make: any) => {
      if (dataSource.value === 'bottomUp') {
        return
      }
      if (lockStatus) {
        return message.error('Planning already locked.')
      }
      Modal.confirm({
        title: 'Tips',
        content: 'Note that take propose will overwrite existing data.',
        okText: 'Confirm',
        async onOk() {
          const params = {
            planningId: tableData.value[index].planningId,
            month,
            bu: topData.value.bu,
            buId: topData.value.buId,
            kufriVersion: make.currentKufriVersion
          }
          const response = await refreshBudget(params)
          const budgetObject = response.data
          make.detailVOList.forEach((row: any) => {
            if (budgetObject) {
              if (row[monthEn].controllingPoint != budgetObject[`${row.mappingName}`]) {
                row[monthEn].edited = true
              }  
              row[monthEn].controllingPoint = budgetObject[`${row.mappingName}`]                    
            } else {
              if (row[monthEn].controllingPoint) {
                row[monthEn].edited = true
              }  
              row[monthEn].controllingPoint = null           
            }
            if (row.children) {
              row.children.forEach((child: any) => {
                if (budgetObject) {                 
                  if (child[monthEn].controllingPoint != budgetObject[`${child.mappingName}`]) {
                    child[monthEn].edited = true
                  } 
                  child[monthEn].controllingPoint = budgetObject[`${child.mappingName}`]   
                } else {
                  if (child[monthEn].controllingPoint) {
                    child[monthEn].edited = true
                  }
                  child[monthEn].controllingPoint = null
                }   
              })
            }
          })
        },
        cancelText: 'Cancel',
      })
    }

    watch(expandAllStatus, (value: boolean) => {
      store.commit(
        'updateSpinning',
        true
      )
      if (value) {
        tableData.value.forEach((make: any, index: number) => {
          expandedRowKeysList.value[index] = make.detailVOList.map((item: any) => {
            return item.id
          })
        })
      } else {
        expandedRowKeysList.value.forEach((expandedRowKeys: any) => {
          expandedRowKeys = expandedRowKeys.splice(0, expandedRowKeys.length)
        })
      }
      store.commit(
        'updateSpinning',
        false
      )
    })

    const handleExpand = (expanded: any, record: any, index: number) => {
      if (expanded) {
        expandedRowKeysList.value[index]?.push(record.id)
      } else {
        const removeIndex = expandedRowKeysList.value[index]?.indexOf(record.id)
        if (index !== -1) {
          expandedRowKeysList.value[index]?.splice(removeIndex, 1)
        }
      }
      expandedRowKeysList.value[index]
    }

    const handleExpandAll = () => {
      if (!expandAllStatus.value) {
        tableData.value.forEach((make: any, index: number) => {
          expandedRowKeysList.value[index] = make.detailVOList.map((item: any) => {
            return item.id
          })
          expandAllStatus.value = true
        })
      } else {
        expandedRowKeysList.value.forEach((expandedRowKeys: any) => {
          expandedRowKeys = expandedRowKeys.splice(0, expandedRowKeys.length)
        })
        expandAllStatus.value = false
      }
    }

    const handleSaveOrRealease = async (type: string) => {
      if (lockStatus) {
        return message.error('Data locked.')
      }
      const postData: any = {}
      const infoSaveDTOList: any[] = []
      tableData.value.forEach(make => {
        const makeItem: any = {}
        const resultList: any = methodFormatSaveData(make, type)
        if (type === 'release') {
          resultList.forEach((item: any) => {
            item.controllingReleasedPoint = item.controllingSavedPoint
          })
        }
        makeItem.buSaveStatus = make.buSaveStatus
        makeItem.infoList = resultList
        makeItem.kufriVersion = make.viewKufriVersion || '2021K08'
        makeItem.make = make.make
        makeItem.planningId = make.planningId
        makeItem.releaseStatus = type === 'release' ? true : make.releaseStatus
        makeItem.submitStatus = make.submitStatus
        makeItem.controllingSaveStatus = true
        if (make.detailVOList?.length > 0) {
          infoSaveDTOList.push(makeItem)
        }       
      })
      postData.buId = topData.value.buId
      postData.bu = topData.value.bu
      postData.infoSaveDTOList = infoSaveDTOList
      await saveGuideline(postData)
      message.success('Operation Succeeded')
      if (type === 'save') {
        expandAllStatus.value = false
        methodGetList()
      }
    }

    const handleSetBottomUpYear = () => {
      setBottomUpYearShow.value = true
    }

    // onMounted(() => {
    //   tableData.forEach(make => {
    //     make.totalExpand = false
    //     expandedRowKeysList.push([])
    //     methodFormatList(make.detailVOList)
    //   })
    // })
    const spreadVisible = ref<boolean>(false)
    const spreadData = ref<any []>([])
    const actuals = ref<boolean[]>([])
    const currentMake = ref<any[]>([])
    const currentMakeName = ref<string>('')

    const handleBatchInput = (make: any, makeIndex: number) => {
      spreadVisible.value = true
      spreadData.value = make.detailVOList || []
      currentMake.value = make.detailVOList
      currentMakeName.value = make.make
      actuals.value = months.value.map(month => {
        return month.actualStatus[makeIndex]
      })
    }

    const handleSpreadJsUpdate = (spreadJsData: any) => {
      formatDataForTable(currentMake.value, spreadJsData, 'controllingPoint')
    }

    const handleCloseSpreadJsDialog = () =>  {
      spreadVisible.value = false
    }

    

    return {
      spreadVisible,
      spreadData,
      currentMakeName,
      handleBatchInput,
      actuals,
      handleSpreadJsUpdate,
      handleCloseSpreadJsDialog,
      scenarioName,
      lockStatus,
      topData,
      setBottomUpYearShow,
      setBuYear,
      years,
      dialogYears,
      currentYear,
      prePoint,
      tableColumns,
      tableData,
      seasons,
      months,
      dataSource,
      seasonTotal,
      expandAllStatus,
      expandedRowKeysList,
      number2Fixed,
      handleUpdate,
      handleKufriChange,
      handleSetBuYearChange,
      handleModalOk,
      handleChooseYear,
      handleClickCell,
      handleFocus,
      handleEnter,
      handleBlur,
      handleForecast,
      handleExpand,
      handleExpandAll,
      handleSaveOrRealease,
      handleSetBottomUpYear
    }
  }
})
